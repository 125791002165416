/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import {
  gameReconnect,
  doubleConnect,
  classicConnect,
  gameUpdateTimer,
  doubleConnected,
  classicConnected,
  gameUpdateStatus,
  doubleMakeBetResponse,
  classicMakeBetResponse,
} from "./actions/game";
import {
  getBalanceRequest,
  setBalanceResponse,
  updateTokens,
} from "./actions/balance";
import { SOCK_RESPONSES, SOCK_SYSTEM } from "./constants/socket";
import { destroySocket, installSocket } from "./actions/socket";
import { chatConnected, chatUpdate } from "./actions/chat";
import { updateTotalOnline } from "./actions/online";
import { ROUTES } from "./constants/routes";

export const AppGateway = () => {
  const [reconnectTimer, setReconnectTimer] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const dispatch = useDispatch();

  const { user, game } = useSelector(({ user: userData, game: gameData }) => ({
    user: userData?.data?.data,
    game: gameData?.data,
  }));

  /** Reconnecting */

  const gamesReconnect = () => {
    if (window.location.pathname === ROUTES.DOUBLE) dispatch(doubleConnect());
    else dispatch(classicConnect());
  };

  const handleDisconnected = () => setIsConnected(false);
  const handleConnected = () => setIsConnected(true);

  useEffect(() => {
    if (isConnected) {
      if (user?.id) dispatch(getBalanceRequest());
      gamesReconnect();
    }
  }, [isConnected, user?.id]);

  useEffect(() => {
    if (reconnectTimer) {
      clearInterval(reconnectTimer);
      setReconnectTimer(null);
    }

    let timer = null;

    if (!game?._id) {
      timer = setInterval(() => gamesReconnect(), 1000);
      setReconnectTimer(timer);
    }

    return () => {
      if (timer) {
        setReconnectTimer(null);
        clearInterval(timer);
      }
    };
  }, [game?._id]);

  /** Install socket */

  useEffect(() => {
    /** Distributers */
    const lDstr = (p, cb) => {
      const { userId = null } = p;
      if (user?.id === userId && userId) cb(p);
    };

    const gameDstr = (p, cb) => {
      const cPath = window.location.pathname === ROUTES.HOME;
      const dPath = window.location.pathname === ROUTES.DOUBLE;
      if (cPath && p.room !== "GAMES_CLASSIC_DEFAULT") return null;
      if (dPath && p.room !== "GAMES_DOUBLE_DEFAULT") return null;
      cb(p);
    };

    const classicDtsr = (p, cb) => {
      if (window.location.pathname !== ROUTES.HOME) return null;
      cb(p);
    };

    const doubleDtsr = (p, cb) => {
      if (window.location.pathname !== ROUTES.DOUBLE) return null;
      cb(p);
    };

    /** Listeners of responses */

    const makeBetClassic = (v) => {
      dispatch(classicMakeBetResponse(v));
    };
    const makeBetDouble = (v) => dispatch(doubleMakeBetResponse(v));
    const roomDestroyed = () => {};
    const connectClassic = (v) =>
      classicDtsr(v, (p) => dispatch(classicConnected(p)));

    const connectChat = (v) => {
      dispatch(chatConnected(v));
    };
    const updateChat = (v) => {
      dispatch(chatUpdate(v));
    };

    const connectDouble = (v) =>
      doubleDtsr(v, (p) => dispatch(doubleConnected(p)));

    // Already connected! Does not require reconnection!
    const gameCreated = (v) => dispatch(gameReconnect(v));

    /* Updaters */

    const updateTimer = (v) => gameDstr(v, (p) => dispatch(gameUpdateTimer(p)));
    const updateBalanceTokens = (v) => dispatch(updateTokens(v));
    const updateStatus = (v) => dispatch(gameUpdateStatus(v));
    const totalOnline = (v) => dispatch(updateTotalOnline(v));
    const ping = () => console.log("pong");

    const userUpdateBalance = (v) =>
      lDstr(v, (p) => dispatch(setBalanceResponse(p)));

    const EVENTS = {
      /** Response actions (One user <--> backend) */
      [SOCK_RESPONSES.USER_BALANCE_UPDATE]: userUpdateBalance,
      [SOCK_RESPONSES.CONNECT_CLASSIC_ROOM]: connectClassic,
      [SOCK_RESPONSES.CONNECT_DOUBLE_ROOM]: connectDouble,
      [SOCK_RESPONSES.CHAT_CONNECT]: connectChat,
      [SOCK_RESPONSES.CHAT_UPDATE]: updateChat,
      [SOCK_RESPONSES.PING]: ping,

      /** Local actions (many users <- backend) */
      [SOCK_RESPONSES.MAKE_BET_CLASSIC_ROOM]: makeBetClassic,
      [SOCK_RESPONSES.MAKE_BET_DOUBLE_ROOM]: makeBetDouble,
      [SOCK_RESPONSES.GAME_UPDATE_STATUS]: updateStatus,
      [SOCK_RESPONSES.GAME_UPDATE_TIMER]: updateTimer,

      /** System actions (all users <- backend) */
      [SOCK_SYSTEM.TOKENS_UPDATED]: updateBalanceTokens,
      [SOCK_SYSTEM.UPDATE_TOTAL_ONLINE]: totalOnline,
      [SOCK_SYSTEM.ROOM_DESTROYED]: roomDestroyed,
      [SOCK_SYSTEM.GAME_CREATED]: gameCreated,
    };

    const listeners = [];

    Object.keys(EVENTS).forEach((event) => {
      listeners.push({ event, callback: EVENTS[event] });
    });
    dispatch(installSocket(listeners, { handleDisconnected, handleConnected }));
    return () => dispatch(destroySocket(listeners));
  }, [user?.id]);
};
