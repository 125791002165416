import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { gameIsRecruiting } from "../../utils/game-get-view-status";
import { gameGetTimeLeft } from "../../utils/game-get-time-left";
import { GAME_STATUSES } from "../../constants/games";
import DoubleBetView from "./View";

const DoubleBet = () => {
  const { gameData, balanceData, user } = useSelector(
    ({ game, balance, user: userData }) => ({
      user: userData?.data?.data,
      balanceData: balance?.data,
      gameData: game?.data,
    })
  );

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [isAlreadyInGame, setIsAlreadyInGame] = useState(false);

  useEffect(() => {
    if (balanceData?.length && !selectedCurrency) {
      setSelectedCurrency(balanceData[0]);
    }
  }, [balanceData?.length]);

  useEffect(() => {
    if (balanceData?.length && selectedCurrency?.code) {
      const curr = selectedCurrency;
      const findedBalance = balanceData.find((b) => b.code === curr.code);
      setSelectedCurrency(findedBalance);
    }
  }, [balanceData]);

  const isRecruit = gameIsRecruiting(gameData);
  const timeLeft = gameGetTimeLeft(gameData);

  useEffect(() => {
    if (gameData.status === GAME_STATUSES.RECRUITED) setIsAlreadyInGame(true);
  }, [timeLeft, gameData.status]);

  const userBets = gameData?.bets?.filter?.((b) => b?.user?._id === user?.id);

  return (
    <DoubleBetView
      winNumber={gameData?.roundColorNumber}
      isAlreadyInGame={isAlreadyInGame}
      winColor={gameData?.roundColor}
      status={gameData?.status}
      isRecruit={isRecruit}
      userBets={userBets}
    />
  );
};

export default DoubleBet;
