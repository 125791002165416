/* eslint-disable react/no-array-index-key */
/* eslint-disable react/react-in-jsx-scope */
import "./index.scss";

import { currencyCodeParams } from "../../utils/currencyOptions";

const Crypto = ({ game }) => {
  if (game?.lenght) return null;

  return (
    <div className="crypto">
      <div className="crypto__container">
        <div className="crypto__coin-box">
          <div>
            {game.bets.map((bet, index) => {
              return (
                <div className="crypto__scroll">
                  <div className="crypto__coin" key={index}>
                    <img
                      src={currencyCodeParams[bet.tokenCode]?.icon}
                      className="crypto__usdt"
                      alt="usdt"
                    />
                    <span className="crypto__coin-value">{bet.sumToken}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crypto;
