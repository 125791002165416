/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import "./index.scss";

import { currencyCodeParams } from "../../utils/currencyOptions";
import { Text } from "../../utils/Text";
import { fix2, fix8 } from "../../utils/fixers";

const CoinPopUPView = ({
  balanceHideZero,
  isCoinPopUPOpen,
  handleHideZero,
  handleSelect,
  balanceFiat,
  handleFiat,
  balances,
}) => {
  const filteredBalances = balanceHideZero
    ? balances.filter((b) => Number(b.amount))?.length
      ? balances.filter((b) => Number(b.amount))
      : balances
    : balances;

  return (
    <div
      className="user-coins"
      style={{
        opacity: isCoinPopUPOpen ? 1 : 0,
        visibility: isCoinPopUPOpen ? "visible" : "hidden",
      }}
    >
      <div className="user-coins__coin-box">
        {filteredBalances.map((balance, i) => {
          const usdt = fix2(balance?.sumUsdt || 0);

          const amount =
            balance.code === "usd" ? usdt : fix8(balance?.amount || 0);
          return (
            <div
              className="user-coins__coin-container"
              onClick={() => handleSelect(balance)}
              key={i}
            >
              <div className="user-coins__total">
                {balanceFiat ? `${usdt}$` : amount}
              </div>
              <div className="user-coins__coin">
                {balance.name}
                {currencyCodeParams[balance.code]?.icon ? (
                  <img
                    className="user-coins__custom-option__icon"
                    src={currencyCodeParams[balance.code]?.icon}
                    alt={balance.name}
                    style={{ maxWidth: "16px" }}
                  />
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
      <hr className="user-coins__line" />
      <div>
        <div className="walletSettings__switch">
          <div className="walletSettings__switch-box">
            <label className="label toggle">
              <input
                type="checkbox"
                className="toggle_input"
                checked={balanceFiat}
                onChange={handleFiat}
              />
              <div className="toggle-control" />
            </label>
          </div>
          <div className="walletSettings__switch-tex-box">
            <p className="walletSettings__switch-text">
              <Text tid="WALLET.WALLET_POPUP.DISPLAY" />
            </p>
          </div>
        </div>
        {balances.filter((b) => Number(b.amount))?.length ? (
          <div className="walletSettings__switch">
            <div className="walletSettings__switch-box">
              <label className="label toggle">
                <input
                  type="checkbox"
                  className="toggle_input"
                  checked={balanceHideZero}
                  onChange={handleHideZero}
                />
                <div className="toggle-control" />
              </label>
            </div>
            <div className="walletSettings__switch-tex-box">
              <p className="walletSettings__switch-text">
                <Text tid="WALLET.WALLET_POPUP.HIDE" />
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CoinPopUPView;
