import React from "react";
import { useSelector } from "react-redux";
import BetView from "./View";

const Bet = ({ handleBidPopUpClick, isOpenTrigger, handleHashOpen }) => {
  const { isConnecting, gameData, user } = useSelector(
    ({ game, user: userData }) => ({
      isConnecting: game.connecting,
      user: userData?.data?.data,
      gameData: game.data,
    })
  );

  if (isConnecting) return null;

  const winInfo = gameData.members.find((member) => member._id === user?.id);

  return (
    <BetView
      handleBidPopUpClick={handleBidPopUpClick}
      handleHashOpen={handleHashOpen}
      roundHash={gameData.roundHash}
      isOpenTrigger={isOpenTrigger}
      winInfo={winInfo}
      user={user}
    />
  );
};

export default Bet;
