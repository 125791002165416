import axios from "axios";

import { API } from "../constants/api";
import { USERS_TOP_CLASSIC } from "../reducers/topClassic";
import { TOP_TYPES } from "../constants/top-type";

const setLoading = () => {
  return { type: USERS_TOP_CLASSIC.LOADING };
};

const setLoaded = (payload) => {
  return { type: USERS_TOP_CLASSIC.LOADED, payload };
};

const setTodayTopLoading = () => {
  return { type: USERS_TOP_CLASSIC.LOADING_TODAY_TOP };
};

const setTodayTopLoaded = (payload) => {
  return { type: USERS_TOP_CLASSIC.LOADED_TODAY_TOP, payload };
};

export const getUsersTopClassic = (topType) => {
  return async (dispatch) => {
    dispatch(setLoading());

    if (topType === TOP_TYPES.TODAY) {
      dispatch(setTodayTopLoading());
    }

    try {
      const response = await axios.post(API.GET_TOP, { topType });
      dispatch(setLoaded(response.data));

      if (topType === TOP_TYPES.TODAY && response?.data?.users) {
        dispatch(setTodayTopLoaded(response.data.users.slice(0, 3)));
      }
    } catch (error) {
      console.error(error);
    }
  };
};
