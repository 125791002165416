import React, { useState } from "react";
import "./index.scss";
import TopOf from "../TopOf";
import HeaderTop from "../HeaderTop";
import TopWinners from "../../containers/TopWinners/index";
import { withProvider } from "../../utils";
import { TOP_TYPES } from "../../constants/top-type";

const TopTab = () => {
  const [currentTopTime, setCurrentTopTime] = useState(TOP_TYPES.TODAY);

  return (
    <div className={`body ${withProvider() && "provider"}`}>
      <HeaderTop
        currentTopTime={currentTopTime}
        setCurrentTopTime={setCurrentTopTime}
      />
      <TopOf currentTopTime={currentTopTime} />
      <TopWinners />
    </div>
  );
};

export default TopTab;
