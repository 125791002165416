import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import { gameIsRecruiting } from "../../utils/game-get-view-status";
import { gameGetTimeLeft } from "../../utils/game-get-time-left";
import { TrueGameComponent } from "../../components/TrueGame";
import { GAME_STATUSES } from "../../constants/games";
import { ClassicGameRoulette } from "./Roulette";
import { ClassicGameMakeBet } from "./MakeBet";
import { ClassicGamePlayers } from "./Players";
import Chance from "../../components/Chance";
import HeaderBody from "../HeaderBody/index";
import Crypto from "../../components/Crypto";
import Loader from "../../components/Loader";
import History from "../ClassicGameHistory";
import "./index.scss";
import { withProvider } from "../../utils";

const ClassicGameView = ({
  handleBidPopUpClick,
  isOpenTrigger,
  members = [],
  currency,
  loading,
  isFiat,
  bets,
  user,
}) => {
  const { FINISHED, PAUSED, SPIN } = GAME_STATUSES;

  const gameData = useSelector(({ game }) => game.data);
  const rec = gameIsRecruiting(gameData);

  const [isAlredyInGame, setIsAlreadyInGame] = useState(false);
  const [isHashOpen, setIsHashOpen] = useState(false);
  const [shouldMove, setShouldMove] = useState(false);

  const timeLeft = gameGetTimeLeft(gameData);

  const handleHashOpen = () => setIsHashOpen(true);
  const handleHashClose = () => setIsHashOpen(false);

  useEffect(() => {
    if (gameData.status === GAME_STATUSES.RECRUITED) setIsAlreadyInGame(true);
  }, [timeLeft]);

  useEffect(() => {
    if (rec) setShouldMove(false);
    if (rec) setIsAlreadyInGame(true);
    if (gameData.status === SPIN && gameData?.members?.length) {
      setShouldMove(true);
    }
  }, [gameData.status]);

  const isMob = window.innerWidth <= 1142;

  const isShowWinner =
    (!rec && !isAlredyInGame) ||
    gameData.status === FINISHED ||
    gameData.status === PAUSED;

  let className = "classic-wrapper";
  if (isShowWinner) className += " show-winner";
  if (rec) className += " recruit";

  return (
    <div
      className={`body ${withProvider() && "provider"}`}
      style={isOpenTrigger && isMob ? { width: "100%" } : {}}
    >
      <HeaderBody />
      <Routes>
        <>
          <Route
            path="/history"
            element={
              <>
                <History />
                <Chance />
                <Crypto />
              </>
            }
          />
          <Route
            path="/"
            element={
              !loading ? (
                <div className={className}>
                  <div>
                    <ClassicGameRoulette
                      isAlredyInGame={isAlredyInGame}
                      shouldMove={shouldMove}
                      gameData={gameData}
                    />
                  </div>
                  <div>
                    <ClassicGameMakeBet
                      handleBidPopUpClick={handleBidPopUpClick}
                      handleHashOpen={handleHashOpen}
                      isOpenTrigger={isOpenTrigger}
                      isShowWinner={isShowWinner}
                      currency={currency}
                      isRecruit={rec}
                    />
                  </div>
                  <div>
                    <ClassicGamePlayers
                      members={members}
                      isFiat={isFiat}
                      user={user}
                      bets={bets}
                    />
                  </div>
                </div>
              ) : (
                <div className="to-connecting-block">
                  <Loader />
                </div>
              )
            }
          />
        </>
      </Routes>
      <TrueGameComponent
        roundHash={gameData.roundHash}
        handleClose={handleHashClose}
        isOpenHashPopup={isHashOpen}
      />
    </div>
  );
};

export default ClassicGameView;
