import { io as socketClient } from "socket.io-client";
import { SOCK_REQUESTS } from "../constants/socket";
import { getWsServer } from "../constants/api";
import { getCookie } from "../utils/cookie";
import { SOCKET } from "../reducers/socket";
import { COOKIE_AUTH } from "../constants";

const __io = socketClient("", { autoConnect: false });

const install = (payload) => ({ type: SOCKET.INSTALL, payload });

export const connectRoomDouble = () => {};

export class SocketWrapper {
  constructor(socket) {
    const emit = (type, data) => socket.emit(type, data || {});
    const on = (type, callback) => socket.on(type, callback);
    const off = (type) => socket.off(type);
    const close = () => socket.close();
    this.socket = { ...socket, off, emit, on, close };
  }
}

export const installSocket = (listeners = [], connectionHandlers = {}) => {
  return async (dispatch) => {
    const token = getCookie(COOKIE_AUTH);
    const headers = { extraHeaders: { Authorization: `Bearer ${token}` } };

    __io.io.uri = getWsServer();
    __io.io.opts.reconnection = true;
    __io.io.opts.reconnectionAttempts = 120;
    __io.io.opts.transportOptions = { polling: headers };

    const { handleDisconnected, handleConnected } = connectionHandlers;

    __io.on("disconnect", handleDisconnected);
    __io.io.on("reconnect", handleConnected);
    __io.on("connect", handleConnected);

    __io.connect();

    listeners.forEach((l) => __io.on(l.event, l.callback));
    dispatch(install(new SocketWrapper(__io)));
    if (token) __io.emit(SOCK_REQUESTS.USER_BALANCE_UPDATE, {});
    return () => __io.close();
  };
};

export const destroySocket = (listeners) => {
  return async (dispatch, getState) => {
    listeners.forEach((l) => __io.off(l.event, l.callback));
    const st = getState().socket;
    st?.close?.();
    dispatch(
      install({
        close: () => null,
        emit: () => null,
        off: () => null,
        on: () => null,
      })
    );
  };
};
