import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  classicMakeBetRequest,
  doubleMakeBetRequest,
} from "../../actions/game";
import { GAME_STATUSES, GAME_TYPES } from "../../constants/games";
import { useDebouncedEffect } from "../../utils";
import { fix0, fix1, fix2, fix8 } from "../../utils/fixers";
import { BetModalView } from "./BetModalView";
import { axios } from "../../utils/request";
import { API } from "../../constants/api";
import "./BetModal.scss";

export const BetModal = ({ listenHandleBet = () => {}, isCrash }) => {
  const { gameTotalBank, user, gameData, balanceData, isFiat, isHide } =
    useSelector(({ game, balance, user: userData }) => ({
      gameTotalBank: game?.data?.totalBank,
      user: userData?.data?.data,
      balanceData: balance?.data,
      isFiat: balance?.isFiat,
      isHide: balance?.isHide,
      gameData: game?.data,
    }));

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currency, setCurrency] = useState(null);

  const isClassic = gameData.type === GAME_TYPES.CLASSIC;

  const range =
    currency?.code === "usdt" || currency?.code === "usdc" || isFiat
      ? 10
      : 0.01;

  const isUsd = range === 10;

  const [rangeAmountLoading, setRangeAmountLoading] = useState(null);
  const [usdAmountLoading, setUsdAmountLoading] = useState(true);
  const [input, setInput] = useState(range || 10);
  const [usdAmount, setUsdAmount] = useState(0);
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);

  const handleIncrease = () => {
    setInput((v) => {
      const res = Number(v) + range;
      return isUsd ? fix0(res) : fix2(res);
    });
  };

  const handleDecrease = () => {
    setInput((v) => {
      const res = Number(v) - range;
      return res <= 0 ? v : isUsd ? fix0(res) : fix2(res);
    });
  };

  const handleDouble = () =>
    setInput((v) => {
      const res = Number(v) * 2;
      return res >= maxAmount ? v : isUsd ? fix2(res) : fix8(res);
    });

  const handleHalf = () =>
    setInput((v) => {
      const res = Number(v) / 2;
      return res <= minAmount ? v : isUsd ? fix2(res) : fix8(res);
    });

  const handleMax = () =>
    setInput(() => {
      let res = isFiat ? currency.sumUsdt : currency.amount;
      if (res > maxAmount) res = maxAmount;
      return isFiat ? fix2(res) : fix8(res);
    });

  const handleMin = () =>
    setInput(() => (isFiat ? fix2(minAmount) : fix8(minAmount)));

  const handleInput = (e) => {
    const n = e.target.value;
    if (isNaN(Number(n))) return e.preventDefault();
    if (n.split(".")?.[1]?.length > 8) return e.preventDefault();
    if (Number(n) > maxAmount) return e.preventDefault();
    return setInput(n);
  };

  const handleBet = (color) => {
    listenHandleBet(color);

    if (isClassic) {
      const payload = {
        sumToken: Number(input),
        gameId: gameData._id,
        balance: currency,
        isFiat,
      };

      dispatch(classicMakeBetRequest(payload));
    } else {
      let gameColor = "RED";
      if (color === "green") gameColor = "ZERO";
      if (color === "black") gameColor = "BLACK";

      dispatch(
        doubleMakeBetRequest({
          sumToken: Number(input),
          gameId: gameData._id,
          balance: currency,
          color: gameColor,
          isFiat,
        })
      );
    }
  };

  const getFutureChance = () => {
    const { members = [] } = gameData || {};

    if (!members?.length) return 100;

    const userTotalBank =
      members.find((member) => member?._id === user?.id)?.totalBank || 0;

    const totalTickets = gameTotalBank + (Number(input) || 0);

    const chance =
      ((userTotalBank + (Number(input) || 0)) / totalTickets) * 100;

    return fix1(chance);
  };

  useEffect(() => {
    setUsdAmountLoading(true);
  }, [Number(input)]);

  useEffect(() => {
    if (balanceData?.length && !currency) setCurrency(balanceData[0]);
  }, [balanceData?.length]);

  useEffect(() => {
    if (balanceData?.length && currency?.code) {
      const curr = currency;
      const findedBalance = balanceData.find((b) => b.code === curr.code);
      setCurrency(findedBalance);
    }
  }, [balanceData]);

  useEffect(() => {
    if (currency) {
      setRangeAmountLoading(true);
      setInput(range);

      setTimeout(() => {
        axios
          .get(API.RANGE_AMOUNT_BET(currency?.code))
          .then(({ data }) => {
            setRangeAmountLoading(false);
            setMinAmount(isFiat ? data.minUsdt : data.min);
            setMaxAmount(isFiat ? data.maxUsdt : data.max);
          })
          .catch((e) => {
            setRangeAmountLoading(false);
          });
      }, 1000);
    }
  }, [currency?.code, isFiat]);

  useDebouncedEffect(
    async () => {
      if (Number(input) && currency && !isFiat) {
        setUsdAmountLoading(true);
        await axios
          .post(API.CONVERT_TOKEN_TO_USDT, {
            code: currency.code,
            sum: Number(input),
          })
          .then(({ data }) => {
            setUsdAmountLoading(false);
            setUsdAmount(data);
          })
          .catch(() => setUsdAmountLoading(false));
      } else if (Number(input) && currency && isFiat) {
        setUsdAmountLoading(false);
        setUsdAmount(Number(input));
      }
    },
    [Number(input), currency?.code, isFiat],
    200
  );

  let errMsg = null;
  let err = false;

  if (currency) {
    const max = maxAmount || 0;
    const min = minAmount || 0;
    const curr = (isFiat ? currency.sumUsdt : currency.amount) || 0;
    const inpAmount = Number(input);

    const tMacros = (TYPE, v) => t(`DOUBLE.DOUBLE_TAB.BET_ERRORS.${TYPE}`, v);

    if (isNaN(inpAmount)) errMsg = tMacros("UNEXPECTED");
    if (inpAmount < min) errMsg = tMacros("MIN", { min });
    if (inpAmount > curr) errMsg = tMacros("BALANCE");
    else if (inpAmount > max) errMsg = tMacros("MAX", { max });

    if (errMsg?.length) err = true;
  } else err = true;

  if (gameData?.status !== GAME_STATUSES.RECRUITED) err = true;

  const userBets = gameData?.bets?.filter?.((b) => b?.user?._id === user?.id);
  const chance = getFutureChance();

  const filteredBalances = isHide
    ? balanceData.filter((b) => Number(b.amount))?.length
      ? balanceData.filter((b) => Number(b.amount))
      : balanceData
    : balanceData;

  return (
    <BetModalView
      isCrash={isCrash}
      rangeAmountLoading={rangeAmountLoading}
      usdAmountLoading={usdAmountLoading}
      handleIncrease={handleIncrease}
      handleDecrease={handleDecrease}
      handleDouble={handleDouble}
      balanceData={filteredBalances}
      setCurrency={setCurrency}
      handleInput={handleInput}
      handleHalf={handleHalf}
      handleMax={handleMax}
      handleMin={handleMin}
      isClassic={isClassic}
      handleBet={handleBet}
      minAmount={minAmount}
      maxAmount={maxAmount}
      usdAmount={usdAmount}
      userBets={userBets}
      currency={currency}
      isFiat={isFiat}
      chance={chance}
      errMsg={errMsg}
      input={input}
      user={user}
      err={err}
    />
  );
};
