import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import {
  getClassicHistory,
  getClassicHistoryPagination,
} from "../../actions/historyClassic";
import HeaderBody from "../../containers/HeaderBody/index";
import History from "../../containers/ClassicGameHistory";
import { Text } from "../../utils/Text";
import Loader from "../Loader";
import Crypto from "../Crypto";
import Chance from "../Chance";
import "./index.scss";
import { withProvider } from "../../utils";

const HistoryTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { classicHistory, user } = useSelector(
    ({ historyClassic, user: userData }) => ({
      classicHistory: historyClassic.request.data,
      user: userData?.data?.data,
    })
  );

  const handleCopyClick = (hash) => {
    navigator.clipboard.writeText(hash).then(() => {
      toast.success(`${t("MAIN.MAIN.COPY")}`, {
        autoClose: 5000,
        className: "toast-message",
      });
    });
  };

  useEffect(() => {
    dispatch(getClassicHistory());
  }, []);

  const handleScroll = () => {
    const lastBlock = document.querySelector(".history-block:last-child");
    const lastBlockOffset = lastBlock.offsetTop + lastBlock.clientHeight;
    const pageOffset = window.scrollY + window.innerHeight;
    if (pageOffset > lastBlockOffset) dispatch(getClassicHistoryPagination());
  };

  useEffect(() => {
    if (classicHistory && classicHistory?.length) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [classicHistory]);

  if (!classicHistory) {
    return (
      <div className="body">
        <HeaderBody />
        <div className="info-empty">
          <Loader />
        </div>
      </div>
    );
  }

  if (!classicHistory?.length) {
    return (
      <div className={`body ${withProvider() && "provider"}`}>
        <HeaderBody />
        <div className="body-info-empty">
          <div className="info-empty">
            <Text tid="CLASSIC.CLASSIC_TAB.THERE_ARE_NO_GAMES_AT_THE_MOMENT" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`body ${withProvider() && "provider"}`}>
      <HeaderBody />
      <div
        className="history-block"
        style={{ gap: 16, display: "flex", flexDirection: "column" }}
      >
        {classicHistory.map((game, i) => (
          <div key={i}>
            <History game={game} handleCopyClick={handleCopyClick} />
            <Chance members={game.members} user={user} />
            <Crypto game={game} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HistoryTab;
