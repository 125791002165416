import React, { useState } from "react";

import chat from "../../assets/img/Chat.svg";
import chatDots from "../../assets/img/chat-dots.svg";
import arrow from "../../assets/img/CaretDoubleLeft.svg";
import sendMessage from "../../assets/img/send-message.png";
import doc from "../../assets/img/iconmonstr-document-thin.svg";
import PrimaryButton from "../../components/PrimaryButton";
import { UserProfile } from "../../components/UserProfile";
import { ChatRulePopup } from "../../components/ChatRule";
import x from "../../assets/img/close.svg";
import { Text } from "../../utils/Text";

export const ChatView = ({
  messageValue,
  setMessageValue,
  isChatOpened,
  setIsChatOpened,
  handleSendMessage,
  user: userData,
  messages,
}) => {
  const [isRulesOpen, setIsRulesOpen] = useState(false);

  return (
    <>
      <React.Fragment>
        <div className="chat" onClick={() => setIsChatOpened(true)}>
          <div className="chat__container">
            <div className="chat__arrow">
              <img src={arrow} alt="arrow" />
            </div>
            <div className="chat__icon">
              <img src={chat} alt="chat`s icon" />
            </div>
          </div>
        </div>

        <div
          className={`chat__content chat__full ${isChatOpened ? "active" : ""}`}
          style={{
            position: "relative",
            width: isChatOpened ? "350px" : 0,
            opacity: isChatOpened ? 1 : 0,
            visibility: isChatOpened ? "visible" : "hidden",
            transition: "0.3s ease-in-out",
            zIndex: isChatOpened ? 1002 : -1,
            minWidth: isChatOpened ? "350px" : 0,
          }}
        />
        <div
          className={`chat__content ${isChatOpened ? "active" : ""}`}
          style={{
            opacity: isChatOpened ? 1 : 0,
            visibility: isChatOpened ? "visible" : "hidden",
            transition: "0.3s ease-in-out",
            zIndex: isChatOpened ? 1002 : -1,
            width: isChatOpened ? "350px" : 0,
          }}
        >
          <div className="chat__header">
            <div className="chat__header-title-wrapper">
              <img src={chatDots} alt="chat" />
              <span className="chat__header-title">
                <Text tid="MAIN.CHAT.CHAT" />
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div
                className="chat__header-close-wrapper"
                onClick={() => setIsRulesOpen(!isRulesOpen)}
              >
                <img src={doc} alt="Rules" width={20} />
              </div>
              <div
                className="chat__header-close-wrapper"
                onClick={() => setIsChatOpened(false)}
              >
                <img src={x} alt="close" />
              </div>
            </div>
          </div>
          <div className="chat__messages" id="chat-messages">
            <div className="chat__messages-list">
              {messages
                ? messages.map(({ _id, content: message, user }) => {
                    const { _id: id, nickname, photo } = user;
                    const isOwn = userData?.id === id;

                    return (
                      <React.Fragment key={_id}>
                        <div
                          className={`chat__message ${isOwn ? "active" : ""}`}
                        >
                          <div className="chat__message-avatar">
                            <UserProfile user={user}>
                              <img src={photo} alt="User" />
                            </UserProfile>
                          </div>
                          <p className="chat__message-text">
                            <span className="chat__message-text-primary">
                              {nickname}:&nbsp;
                            </span>

                            <span className="chat__message-text-content">
                              {message}
                            </span>
                          </p>
                        </div>
                      </React.Fragment>
                    );
                  })
                : "Loading"}
            </div>
          </div>
          <form className="chat__footer" onSubmit={handleSendMessage}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <input
                className="LogIn__input"
                type="text"
                placeholder="Enter your message"
                value={messageValue}
                onChange={(e) => {
                  let v = e.target.value;
                  v = v.replace(
                    /[^а-яА-ЯёЁa-zA-Z0-9\s,.!?;:'"()@#$%^&*+/-\\]/g,
                    ""
                  );
                  setMessageValue(v);
                }}
                required
                minLength={1}
                maxLength={160}
              />
              <PrimaryButton
                type="submit"
                style={{
                  height: "50px",
                }}
              >
                <span>
                  <img src={sendMessage} alt="Send" />
                </span>
              </PrimaryButton>
            </div>
            <div className="chat__footer-box">
              <p className="chat__characters-left">
                {160 - messageValue.length}/160{" "}
                <Text tid="MAIN.CHAT.CHARACTERS_LEFT" />
              </p>
            </div>
          </form>
        </div>
      </React.Fragment>
      <ChatRulePopup
        isRulesOpen={isRulesOpen}
        setIsRulesOpen={setIsRulesOpen}
      />
    </>
  );
};
