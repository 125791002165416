import React from "react";

import PlayerBet from "../../components/PlayerBet";
import Chance from "../../components/Chance";

export const ClassicGamePlayers = ({ members, user, bets, isFiat }) => {
  return (
    <div className="classic-wrapper__players">
      <Chance members={members} user={user} />
      <PlayerBet bets={bets} isFiat={isFiat} />
    </div>
  );
};
